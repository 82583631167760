<template>
  <section class="about">
    <h2>{{ $t('header.about') }}</h2>
    <div class="about__information">
      <div class="about__text">{{ $t('view.about.about-text') }}</div>
      <div class="welcome-image-container">
        <img src="@/assets/images/welcome.png" alt="avatar" class="welcome-image">
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.about {
  .about__information {
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 600px) {
      flex-direction: row;
    }

    .about__text {
      flex-basis: 0;
      flex-grow: 1;
      white-space: pre-line;
    }

    .welcome-image-container {
      flex-basis: 0;
      flex-grow: 1;
      .welcome-image {
        max-width: 100%;
        border-radius: var(--border-radius);
      }
    }
  }
}
</style>
